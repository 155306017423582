/* fonts */
@import url('https://fonts.googleapis.com/css?family=Denk+One&display=swap');


@media(min-width:750px) and (min-height: 700px) {
    .Projects {
        height: 100vh;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding-top: 6em !important;
        display: flex;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-left: 2% !important;
        margin-right: 2% !important;
        flex: 1;
        border-radius: 8px;
        padding-right: 2% !important;
        padding-left: 2% !important;
    }
}
@media (max-width:749px) and (max-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-top: 4em !important;
        flex: 1;
        border-radius: 8px;
    }
}

@media (max-width:749px) and (min-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-top: 4em !important;
        flex: 1;
        border-radius: 8px;
    }
}
@media (min-width:749px) and (max-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        padding-top: 3em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
        display: flex;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        flex: 1;
        border-radius: 8px;
        padding-right: 2% !important;
        padding-left: 2% !important;
    }
}



.Box1 {
    margin-left: 2% !important;
    margin-right: 2% !important;
    background: rgba(0,0,0,0.1);
    flex: 1;
    border-radius: 8px;
    padding-right: 2% !important;
    padding-left: 2% !important;
}   

.Header {
    text-align: center;
    font-size: 2.5rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 2% !important;
}

.PicOsakeseuranta {
    max-width: 60%;
    margin-left: 20% !important;
    border-radius: 10px;
    margin-top: 2% !important;
}

.Info {
    text-align: left;
    margin-left: 2% !important;
    font-size: 1.5rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 3% !important;
}
.Info a {
    margin-left: 2% !important;
    text-decoration: none;
}
.Info a:active {
    margin-left: 2% !important;
    text-decoration: none;
    color: white;
}
.Logosheader {
    text-align: center;
    font-size: 2rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 3% !important;
}

.Icons1 {
    text-align: center;
    margin-top: 3.5rem !important;
}
.Icons2 {
    text-align: center;
    margin-top: 3rem !important;
}

.Icon {
    margin-top: 3% !important;
    margin-left: 3% !important;
    padding-bottom: 6% !important;
    height: 3em;
    cursor: pointer;
    transition: transform .2s;
}

.Icon:hover {
    transform: scale(1.1);
}