@import url('https://fonts.googleapis.com/css?family=Denk+One&display=swap');






.Contact {
    height: 100vh;
    background-color: #bdd4e7;
    background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
}

.info {
    font-family: 'Denk One', sans-serif;
    color: white;
    font-size: 2rem;
    padding-top: 15% !important;
    text-align: center;
}

.Logos {
    text-align: center;
    margin-top: 10% !important;
}

.logo {
    height: 3em;
    transition: transform .2s;
    cursor: pointer;
    margin-left: 2% !important;
}

.logo:hover {
    transform: scale(1.2);
}
