@import url('https://fonts.googleapis.com/css?family=Denk+One&display=swap');



/* Leveä ja korkea */
@media(min-width:801px) and (min-height: 617px) {
    .About {
        height: 100vh;
        background-color: #6a93cb;
        background-image: linear-gradient(315deg, #6a93cb 0%, #a4bfef 74%);
        padding-left: 2% !important;
        display: flex;
    }
    .Picture {
        height: 60vh;
        margin-top: 11% !important;
        margin-left: 8% !important;
        border: 10px solid #ddd;
        border-radius: 10px;
        padding: 5px;
    }
    .Item1 {
        margin-top: 13% !important;
        margin-left: 4% !important;
        background: rgba(0,0,0,0.1);
        height: 55vh;
        border-radius: 8px;
        width: 60%;
    }
}
/* Kapea ja lyhyt */
@media (max-width:800px), (max-height:616px){
    .About {
        padding-bottom: 2em !important;
        padding-top:  5em !important;
        background-color: #6a93cb;
        background-image: linear-gradient(315deg, #6a93cb 0%, #a4bfef 74%);
        padding-left: 2% !important;
        display: block;
        text-align: center;
    }
    .Picture {
        height: 60vh;
        margin-top: 8% !important;
        border: 10px solid #ddd;
        border-radius: 10px;
        padding: 5px;
    }
    .Item1 {
        margin-left: 20% !important;
        background: rgba(0,0,0,0.1);
        border-radius: 8px;
        width: 60%;
        text-align: left;
        padding: 5% !important;
    }
}



.Headers {
    margin-left: 2% !important;
    margin-top: 4% !important;
    font-family: 'Denk One', sans-serif;
    font-size: 2.5rem;
    color: white;
}

.Texts {
    font-family: 'Denk One', sans-serif;
    font-size: 1.5rem;
    color: white;
    margin-top: 1% !important;
    margin-left: 3% !important;
    
}
.Texts li {
    margin-top: 2% !important;
}

