.App {
  overflow-x: hidden;
}

* {    
    margin: 0 !important;
    padding: 0 !important;
}



