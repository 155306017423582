@import url(https://fonts.googleapis.com/css?family=Do+Hyeon&display=swap);
@import url(https://fonts.googleapis.com/css?family=Denk+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Denk+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Denk+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Denk+One&display=swap);
.App {
  overflow-x: hidden;
}

* {    
    margin: 0 !important;
    padding: 0 !important;
}




.Nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 3.5em;
    padding-top: 0.9% !important;
    background-color: #b3cdd1;
    background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
    box-shadow: 0 -1px 13px 2px black;
    opacity: 0.9;
}


.Tab {
    text-align: center;
}

.Nav label {
    display: none;
    font-size: 14px;
    margin-left: 2% !important;
    width: 5%
}

.toggle {
    display: none;
}

@media(max-width: 500px) {
    .Nav label {
        display: block;
        cursor: pointer;
        font-size: xx-large;
        color: whitesmoke;
    }
    .Tab {
        text-align: center;
        background-color: #b3cdd1;
        background-image: linear-gradient(315deg, #b3cdd1 0%, #9fa4c4 74%);
        display: none;
    }

    .Tab a {
        font-size: 2em;
        font-family: 'Do Hyeon', sans-serif;
        color: whitesmoke;
        margin: 1% 1.5% !important;
        display: block;
    }
    .toggle:checked + .Tab {
        display: block;
    }


}   



.Tab > .active {
    text-decoration: underline;
}

.Tab a {
    font-size: 2em;
    font-family: 'Do Hyeon', sans-serif;
    color: whitesmoke;
    margin: 1% 1.5% !important;
}
.Tab a:hover {
    text-decoration: underline;
    cursor: pointer;
}





/* fonts */


@media(min-width:750px) and (min-height: 700px) {
    .Projects {
        height: 100vh;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding-top: 6em !important;
        display: flex;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-left: 2% !important;
        margin-right: 2% !important;
        flex: 1 1;
        border-radius: 8px;
        padding-right: 2% !important;
        padding-left: 2% !important;
    }
}
@media (max-width:749px) and (max-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-top: 4em !important;
        flex: 1 1;
        border-radius: 8px;
    }
}

@media (max-width:749px) and (min-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        margin-top: 4em !important;
        flex: 1 1;
        border-radius: 8px;
    }
}
@media (min-width:749px) and (max-height:700px){
    .Projects {
        padding-bottom: 2em !important;
        padding-top: 3em !important;
        width: 100%;
        background-color: #9e8fb2;
        background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
    }
    .Boxes {
        padding: 4em 3em !important;
        display: flex;
    }
    .Box2 {
        background: rgba(0,0,0,0.1);
        flex: 1 1;
        border-radius: 8px;
        padding-right: 2% !important;
        padding-left: 2% !important;
    }
}



.Box1 {
    margin-left: 2% !important;
    margin-right: 2% !important;
    background: rgba(0,0,0,0.1);
    flex: 1 1;
    border-radius: 8px;
    padding-right: 2% !important;
    padding-left: 2% !important;
}   

.Header {
    text-align: center;
    font-size: 2.5rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 2% !important;
}

.PicOsakeseuranta {
    max-width: 60%;
    margin-left: 20% !important;
    border-radius: 10px;
    margin-top: 2% !important;
}

.Info {
    text-align: left;
    margin-left: 2% !important;
    font-size: 1.5rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 3% !important;
}
.Info a {
    margin-left: 2% !important;
    text-decoration: none;
}
.Info a:active {
    margin-left: 2% !important;
    text-decoration: none;
    color: white;
}
.Logosheader {
    text-align: center;
    font-size: 2rem;
    font-family: 'Denk One', sans-serif;
    color: whitesmoke;
    margin-top: 3% !important;
}

.Icons1 {
    text-align: center;
    margin-top: 3.5rem !important;
}
.Icons2 {
    text-align: center;
    margin-top: 3rem !important;
}

.Icon {
    margin-top: 3% !important;
    margin-left: 3% !important;
    padding-bottom: 6% !important;
    height: 3em;
    cursor: pointer;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.Icon:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
/* Leveä ja korkea */
@media(min-width:801px) and (min-height: 617px) {
    .About {
        height: 100vh;
        background-color: #6a93cb;
        background-image: linear-gradient(315deg, #6a93cb 0%, #a4bfef 74%);
        padding-left: 2% !important;
        display: flex;
    }
    .Picture {
        height: 60vh;
        margin-top: 11% !important;
        margin-left: 8% !important;
        border: 10px solid #ddd;
        border-radius: 10px;
        padding: 5px;
    }
    .Item1 {
        margin-top: 13% !important;
        margin-left: 4% !important;
        background: rgba(0,0,0,0.1);
        height: 55vh;
        border-radius: 8px;
        width: 60%;
    }
}
/* Kapea ja lyhyt */
@media (max-width:800px), (max-height:616px){
    .About {
        padding-bottom: 2em !important;
        padding-top:  5em !important;
        background-color: #6a93cb;
        background-image: linear-gradient(315deg, #6a93cb 0%, #a4bfef 74%);
        padding-left: 2% !important;
        display: block;
        text-align: center;
    }
    .Picture {
        height: 60vh;
        margin-top: 8% !important;
        border: 10px solid #ddd;
        border-radius: 10px;
        padding: 5px;
    }
    .Item1 {
        margin-left: 20% !important;
        background: rgba(0,0,0,0.1);
        border-radius: 8px;
        width: 60%;
        text-align: left;
        padding: 5% !important;
    }
}



.Headers {
    margin-left: 2% !important;
    margin-top: 4% !important;
    font-family: 'Denk One', sans-serif;
    font-size: 2.5rem;
    color: white;
}

.Texts {
    font-family: 'Denk One', sans-serif;
    font-size: 1.5rem;
    color: white;
    margin-top: 1% !important;
    margin-left: 3% !important;
    
}
.Texts li {
    margin-top: 2% !important;
}


.Contact {
    height: 100vh;
    background-color: #bdd4e7;
    background-image: linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%);
}

.info {
    font-family: 'Denk One', sans-serif;
    color: white;
    font-size: 2rem;
    padding-top: 15% !important;
    text-align: center;
}

.Logos {
    text-align: center;
    margin-top: 10% !important;
}

.logo {
    height: 3em;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    margin-left: 2% !important;
}

.logo:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.Home {
    height: 100vh;
    width: 100%;
    /*margin-top: 9.5vh !important;*/
    background-color: #734ae8;
    background-image: linear-gradient(315deg, #734ae8 0%, #89d4cf 74%);
    text-align: center;
}
.Text {
    padding-top: 40vh !important;
    color: white;
    font-family: 'Denk One', sans-serif;
    font-size: 2em;

}

.Name {
    display: inline;
    color: palegreen;
}

.MuiSvgIcon-root {
    fill: white !important;
    height: 7em !important;
    width: auto !important;
    margin-top: 15vh !important;
    -webkit-transition: -webkit-transform .1s;
    transition: -webkit-transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s;
    cursor: pointer;
}
.MuiSvgIcon-root:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
